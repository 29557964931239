<template>
    <div class="container mt-5">
      <h2 class="text-center text-primary mb-4">Admin Dashboard Overview</h2>
  
      <!-- Dashboard Cards Row -->
      <div class="row">
        <!-- Insights Card -->
        <div class="col-md-4">
          <div class="card shadow-sm mb-4">
            <div class="card-body">
              <h5 class="card-title">Insights</h5>
              <p class="card-text">Here you'll see insights about the platform...</p>
            </div>
          </div>
        </div>
  
        <!-- Sales Data Card -->
        <div class="col-md-4">
          <div class="card shadow-sm mb-4">
            <div class="card-body">
              <h5 class="card-title">Sales Data</h5>
              <p class="card-text">View the total sales and growth trends...</p>
            </div>
          </div>
        </div>
  
        <!-- User Views Card -->
        <div class="col-md-4">
          <div class="card shadow-sm mb-4">
            <div class="card-body">
              <h5 class="card-title">User Views</h5>
              <p class="card-text">Track user engagement and traffic...</p>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Another Row for More Cards -->
      <div class="row">
        <!-- Latest Orders Card -->
        <div class="col-md-6">
          <div class="card shadow-sm mb-4">
            <div class="card-body">
              <h5 class="card-title">Latest Orders</h5>
              <p class="card-text">See the most recent orders placed...</p>
            </div>
          </div>
        </div>
  
        <!-- Total Orders Card -->
        <div class="col-md-6">
          <div class="card shadow-sm mb-4">
            <div class="card-body">
              <h5 class="card-title">Total Orders</h5>
              <p class="card-text">Check the overall number of orders...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style src="@/assets/admin.css"></style>
  <script>
  export default {
    name: "AdminDashboard"
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 100%;
  }
  
  .card {
    border-radius: 10px;
  }
  
  .card-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .card-text {
    font-size: 1rem;
    color: #6c757d;
  }
  </style>
  