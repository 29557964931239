<template>
  <div id="app">
    <!-- Main app content -->
    <router-view />

    <!-- Global Notification Component -->
    <NotificationPane  />
  </div>
</template>

<script>
import NotificationPane from '@/components/web/Notification.vue';

export default {
  components: {
    NotificationPane
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.editor-container, .admin-content {
  max-width: 100% !important; /* Override any existing max-width */
  width: 100%;
  margin: 0 auto; /* Center content if needed */
  padding: 0 20px; /* Add padding to give some spacing */
}
</style>
