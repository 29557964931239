<template>
    <div class="container">
      <h2 class="mt-5 text-center">Admin Login</h2>
      <form @submit.prevent="login" class="mt-4">
        <div class="mb-3">
          <label for="email" class="form-label">Email address</label>
          <input type="email" v-model="email" class="form-control" id="email" required>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <input type="password" v-model="password" class="form-control" id="password" required>
        </div>
        <button type="submit" class="btn btn-primary w-100">Login</button>
      </form>
    </div>
  </template>
  <style src="@/assets/admin.css"></style>
  <script>
  import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
  import { mapActions } from 'vuex';
  export default {
    data() {
      return {
        email: '',
        password: ''
      };
    },
    methods: {
      ...mapActions(['triggerNotification']),
      login() {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, this.email, this.password)
          .then(async (userCredential) => {
            const user = userCredential.user;
            const token = await user.getIdTokenResult();
  
            if (token.claims.admin) {
              this.$router.push('/admin/dashboard'); // Redirect admin
              this.triggerNotification({
          message: 'Login Successfull, Welcome to Admin Panel',
          type: 'success',
          duration: 3000 // Notification will show for 3 seconds
        });
            } else {
              this.triggerNotification({
          message: 'Login Successfull, Welcome to Admin Panel',
          type: 'error',
          duration: 3000 // Notification will show for 3 seconds
        });
              auth.signOut();
            }
          })
      }
    }
  };
  </script>
  
  <style scoped>
    .container {
      max-width: 400px;
    }
  </style>
  