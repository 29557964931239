<template>
    <div class="blog-manager">
      <h1>Create Blog</h1>
  
      <!-- WYSIWYG Blog Editor -->
      <div ref="editor" class="editor"></div>
  
      <!-- Upload Image Button -->
      <div class="mt-4">
        <input type="file" @change="uploadImage" />
      </div>
  
      <!-- Blog Metadata: Title, Author -->
      <div class="mt-4">
        <label for="title">Blog Title</label>
        <input type="text" id="title" v-model="blogTitle" class="form-control mb-4" placeholder="Enter blog title" />
  
        <label for="author">Author</label>
        <input type="text" id="author" v-model="author" class="form-control mb-4" placeholder="Author Name" />
      </div>
  
      <!-- Save Blog Button -->
      <button class="btn btn-primary" @click="saveBlog">Save Blog</button>
    </div>
  </template>
  
  <script>
  import Quill from 'quill';
  import { collection, addDoc } from 'firebase/firestore';
  import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
  import { db, storage } from '@/firebase'; // Initialize Firebase
  
  export default {
    data() {
      return {
        quill: null,
        blogTitle: '',
        author: '',
        content: '',
        imageURL: '',
      };
    },
    mounted() {
      this.initQuill();
    },
    methods: {
      // Initialize Quill WYSIWYG Editor
      initQuill() {
        this.quill = new Quill(this.$refs.editor, {
          theme: 'snow',
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline'],
              ['image'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ['clean'],
            ],
          },
        });
      },
  
      // Upload Image and Get Image URL
      async uploadImage(event) {
        const file = event.target.files[0];
        if (file) {
          const storagePath = `blogs/${file.name}`;
          const storageReference = storageRef(storage, storagePath);
          await uploadBytes(storageReference, file);
          const downloadURL = await getDownloadURL(storageReference);
          
          // Insert the image into the Quill editor
          const range = this.quill.getSelection();
          this.quill.insertEmbed(range.index, 'image', downloadURL);
        }
      },
  
      // Save Blog to Firestore
      async saveBlog() {
        const content = this.quill.root.innerHTML; // Get the content of the editor
        const blogData = {
          title: this.blogTitle,
          author: this.author,
          content,
          createdOn: new Date(),
        };
        
        try {
          await addDoc(collection(db, 'blogs'), blogData);
          alert('Blog saved successfully!');
        } catch (error) {
          console.error('Error saving blog:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .editor {
    height: 300px;
    border: 1px solid #ccc;
  }
  </style>
  