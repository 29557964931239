<template>
    <div class="user-details-container">
      <h1>Complete Your Profile</h1>
      <form @submit.prevent="completeProfile">
        <div class="input-group">
          <input type="text" placeholder="Username" v-model="username" required>
        </div>
        <div class="input-group">
          <input type="number" placeholder="Age" v-model="age">
        </div>
        <div class="input-group">
          <input type="text" placeholder="Gender" v-model="gender">
        </div>
        <div class="input-group">
          <input type="text" placeholder="Phone Number" v-model="phoneNumber">
        </div>
        <div class="input-group">
          <input type="text" placeholder="Address" v-model="address">
        </div>
        <button type="submit">Save Profile</button>
              <!-- Logout Button -->
      <button class="logout-btn" @click="logout">Logout</button>
      </form>


    </div>
  </template>
  
  <script>
  import { auth, db } from '@/firebase';
  import { doc, getDoc, setDoc } from 'firebase/firestore';
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  
  export default {
    setup() {
        const router = useRouter();
      const userId = ref(auth.currentUser?.uid || null);  // Get the current user's ID
      const username = ref('');
      const age = ref('');
      const gender = ref('');
      const phoneNumber = ref('');
      const address = ref('');
  
      const fetchUserData = async () => {
        try {
          if (userId.value) {
            const userDocRef = doc(db, "users", userId.value);
            const docSnap = await getDoc(userDocRef);
            if (docSnap.exists()) {
              const data = docSnap.data();
              username.value = data.username || '';
              age.value = data.user_age || '';
              gender.value = data.user_gender || '';
              phoneNumber.value = data.user_phonenumber || '';
              address.value = data.user_address || '';
            } else {
              console.log("No such document!");
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error.message);
        }
      };
  
      const completeProfile = async () => {
        try {
          if (userId.value) {
            await setDoc(doc(db, "users", userId.value), {
              username: username.value,
              user_age: age.value,
              user_gender: gender.value,
              user_phonenumber: phoneNumber.value,
              user_address: address.value,
              user_email: auth.currentUser.email // Store email to ensure consistency
            }, { merge: true });  // Merging the new data with existing data
            alert('Profile Updated Successfully');
            // Redirect or handle the success case
          } else {
            alert('User not authenticated');
            // Handle unauthenticated state, like redirecting to the login page
          }
        } catch (error) {
          console.error("Error updating profile:", error.message);
        }
      };
      const logout = async () => {
      try {
        await auth.signOut();
        router.push('/signin'); // Redirect to sign-in page after logout
      } catch (error) {
        console.error("Error logging out:", error.message);
      }
    };
  
      onMounted(() => {
        fetchUserData();
      });
  
      return {
        username,
        age,
        gender,
        phoneNumber,
        address,
        completeProfile,
        logout
      };
    }
  }
  </script>
  
  <style scoped>
  .user-details-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    background-color: #f4f1ea;
    border-radius: 8px;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px 20px;
    background-color: #6a329f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .logout-btn {
    background-color: red;
  }
  
  button:hover {
    background-color: #5a297f;
  }
  </style>
  