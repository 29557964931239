<template>
    <div class="default-container">
      <!-- Top Header with Logo -->
      <header class="top-header">
        <img class="logo" src="../../assets/logo.svg" alt="Tintly Logo">
      </header>
  
      <!-- Main View Area -->
      <main class="main-view">
        <UserSignUp />
      </main>
    </div>
  </template>
  
  <script>
  import UserSignUp from './UserSignUp.vue';
  
  export default {
    name: 'DefaultView',
    components: {
      UserSignUp
    }
  }
  </script>
  
  <style scoped>
  .default-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--background-main-color);
  }
  
  .top-header {
  display: flex;
  justify-content: center;
  align-content: center;
}
.logo {
  font-size: medium;
  width:100vw;
  margin: 15px;
  top: 50%;
}
h1,h2,h3 {
  color: black;
  font-size: 35px;
}
  
  
  /* Main View Area */
  .main-view {
    flex: 1;
    margin-top: 64px; /* Header height */
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  