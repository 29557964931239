<template>
    <div class="hero-banner" :class="{ scrollable: isScrollable }">
      <div v-if="isScrollable" class="banner-slider">
        <div v-for="(banner, index) in banners" :key="index" class="banner-item">
          <img :src="banner" alt="Banner Image" />
          <div v-if="ctaText || buttonText" class="cta-overlay">
            <h2>{{ ctaText }}</h2>
            <a v-if="buttonText" :href="buttonLink" class="cta-button">{{ buttonText }}</a>
          </div>
        </div>
      </div>
      <div v-else class="single-banner">
        <img :src="banners[0]" alt="Banner Image" />
        <div v-if="ctaText || buttonText" class="cta-overlay">
          <h2>{{ ctaText }}</h2>
          <a v-if="buttonText" :href="buttonLink" class="cta-button">{{ buttonText }}</a>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      banners: {
        type: Array,
        required: true,
      },
      isScrollable: {
        type: Boolean,
        default: false,
      },
      ctaText: {
        type: String,
        default: "",
      },
      buttonText: {
        type: String,
        default: "",
      },
      buttonLink: {
        type: String,
        default: "#", // Default link if none provided
      },
    },
  };
  </script>
  
  <style scoped>
  .hero-banner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block;
    position: relative;
  }
  
  .single-banner img {
    overflow: hidden;
    position: relative;
  }
  
  /* Snap Scrolling Styles */
  .banner-slider {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory; /* Ensures horizontal snap */
    scroll-behavior: smooth; /* Smooth snapping */
  }
  
  .banner-item {
      min-width: 100%;
      height: 25vh;
      flex-shrink: 0;
      scroll-snap-align: start; /* Each banner snaps to the start of the viewport */
      position: relative;
  }
  
  .single-banner {
    width: 100%;
    height: 100%;
  }
  
  img {
    width: 100vw;
    height: 100%;
    object-fit: cover;
  }
  
  /* CTA Overlay */
  .cta-overlay {
    position: absolute;
    bottom: 25%;
    left: 75%;
    text-align: center;
    color: white;
    font-family: "Fredoka" sans-serif;
    padding: 10px;
    border-radius: 10px;
  }
  
  .cta-overlay h2 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .cta-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #ff5a5f;
    color: white;
    text-decoration: none;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #ff3e3e;
  }
  </style>
  