<template>
  <div>
    <canvas ref="productCanvas" width="400" height="500" style="border: 1px solid black;"></canvas>
  </div>
</template>

<script>
import { fabric } from 'fabric';

export default {
  name: 'ComponentTester',
  mounted() {
    this.$nextTick(() => {
      const canvas = new fabric.Canvas(this.$refs.productCanvas);

      // Add a rectangle to test
      const rect = new fabric.Rect({
        left: 100,
        top: 100,
        fill: 'red',
        width: 100,
        height: 100
      });
      canvas.add(rect);
    });
  }
};
</script>