<template>
  <div class="collection-page">
    <h1>Collection Management</h1>

    <!-- Form to Create or Update Collection -->
    <form @submit.prevent="saveCollection">
      <div class="mb-3">
        <label for="collectionName">Collection Name</label>
        <input
          type="text"
          id="collectionName"
          class="form-control"
          v-model="collectionData.name"
          placeholder="Enter collection name"
        />
      </div>

      <div class="mb-3">
        <label for="bannerGroup">Select Banner Group</label>
        <select class="form-select" v-model="collectionData.bannerGroupId">
          <option v-for="bannerGroup in bannerGroups" :key="bannerGroup.id" :value="bannerGroup.id">
            {{ bannerGroup.name }}
          </option>
        </select>
      </div>

      <!-- Select Products By Tags or Product List -->
      <div class="mb-3">
        <label for="productTags">Select Products by Tags</label>
        <select class="form-select" v-model="selectedTags" multiple>
          <option v-for="tag in tags" :key="tag" :value="tag">{{ tag }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="manualProductSelection">Manually Select Products</label>
        <select class="form-select" v-model="selectedProducts" multiple>
          <option v-for="product in products" :key="product.id" :value="product.id">
            {{ product.name }}
          </option>
        </select>
      </div>

      <button type="submit" class="btn btn-primary">
        {{ isEditing ? 'Save Changes' : 'Create Collection' }}
      </button>
      <button v-if="isEditing" type="button" class="btn btn-secondary ms-2" @click="resetForm">
        Cancel Edit
      </button>
    </form>

    <!-- Existing Collections -->
    <h2 class="mt-5">Existing Collections</h2>
    <ul class="list-group">
      <li v-for="(col, index) in collections" :key="col.id" class="list-group-item d-flex justify-content-between">
        <span>{{ col.name }}</span>
        <div>
          <button class="btn btn-secondary me-2" @click="editCollection(index)">Edit</button>
          <button class="btn btn-danger" @click="deleteCollection(col.id)">Delete</button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { collection, getDocs, updateDoc, doc, deleteDoc, addDoc } from 'firebase/firestore';
import { db } from '@/firebase'; // Firebase initialization

export default {
  name: "ManageCollection",
  data() {
    return {
      collections: [],
      products: [], // Products array
      collectionData: { name: "", products: [], tags: [], bannerGroupId: "" },
      bannerGroups: [],
      selectedProducts: [],
      selectedTags: [],
      isEditing: false, // Track whether user is editing
      editingIndex: null, // Track the index of the collection being edited
    };
  },
  async created() {
    await this.fetchCollections();
    await this.fetchProducts(); // Fetch products on component creation
    await this.fetchBannerGroups(); // Fetch banner groups
  },
  methods: {
    // Fetch existing collections from Firestore
    async fetchCollections() {
      const querySnapshot = await getDocs(collection(db, 'collections'));
      this.collections = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    // Fetch products from Firestore
    async fetchProducts() {
      const querySnapshot = await getDocs(collection(db, 'products'));
      this.products = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    // Fetch banner groups from Firestore
    async fetchBannerGroups() {
      const querySnapshot = await getDocs(collection(db, 'bannerGroups'));
      this.bannerGroups = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    // Save or update collection
    async saveCollection() {
      if (this.isEditing) {
        const collectionId = this.collections[this.editingIndex].id;
        await updateDoc(doc(db, 'collections', collectionId), {
          name: this.collectionData.name,
          products: this.selectedProducts,
          tags: this.selectedTags,
          bannerGroupId: this.collectionData.bannerGroupId,
        });
      } else {
        await addDoc(collection(db, 'collections'), {
          name: this.collectionData.name,
          products: this.selectedProducts,
          tags: this.selectedTags,
          bannerGroupId: this.collectionData.bannerGroupId,
        });
      }
      this.resetForm();
      await this.fetchCollections(); // Refresh collections after saving
    },

    // Edit an existing collection
    editCollection(index) {
      const col = this.collections[index];
      this.collectionData = {
        name: col.name,
        products: col.products,
        tags: col.tags,
        bannerGroupId: col.bannerGroupId,
      };
      this.selectedProducts = col.products;
      this.selectedTags = col.tags;
      this.isEditing = true;
      this.editingIndex = index; // Set the index for tracking which collection is being edited
    },

    // Delete a collection
    async deleteCollection(collectionId) {
      await deleteDoc(doc(db, 'collections', collectionId));
      await this.fetchCollections(); // Refresh collections after deletion
    },

    // Reset form after saving or canceling
    resetForm() {
      this.collectionData = { name: "", products: [], tags: [], bannerGroupId: "" };
      this.selectedProducts = [];
      this.selectedTags = [];
      this.isEditing = false;
      this.editingIndex = null;
    },
  },
};
</script>

<style scoped>
/* Add any necessary styling */
</style>
