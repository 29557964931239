<template>
  <div class="container mt-5">
    <div class="card shadow-sm p-4 mb-5 bg-white rounded">
      <h2 class="text-center text-primary mb-4">Add New Product</h2>
      <form @submit.prevent="addProduct">
        <!-- Product Fields -->
        <div class="form-group mb-3">
          <label for="name" class="form-label">Product Name</label>
          <input type="text" v-model="newProduct.name" class="form-control form-control-lg" required>
        </div>
        <div class="form-group mb-3">
          <label for="description" class="form-label">Description</label>
          <textarea v-model="newProduct.description" class="form-control form-control-lg" rows="3" required></textarea>
        </div>
        <div class="form-group mb-3">
          <label for="price" class="form-label">Price</label>
          <input type="number" v-model="newProduct.price" class="form-control form-control-lg" required>
        </div>
        <div class="form-group mb-3">
          <label for="stock" class="form-label">Stock</label>
          <input type="number" v-model="newProduct.stock" class="form-control form-control-lg" required>
        </div>

        <!-- Tag and Collection Tag Fields -->
        <div class="form-group mb-3">
          <label for="tags" class="form-label">Tags</label>
          <input type="text" v-model="newTag" class="form-control form-control-lg" 
                 @keydown.enter.prevent="addTag" placeholder="Press enter to add a tag">
          <div class="selected-tags mt-2">
            <span v-for="tag in newProduct.tags" :key="tag" class="badge bg-primary me-2">{{ tag }}</span>
          </div>
        </div>

        <div class="form-group mb-3">
          <label for="collectionTags" class="form-label">Collection Tags</label>
          <input type="text" v-model="newCollectionTag" class="form-control form-control-lg" 
                 @keydown.enter.prevent="addCollectionTag" placeholder="Press enter to add a collection tag">
          <div class="selected-tags mt-2">
            <span v-for="collectionTag in newProduct.collectionTags" :key="collectionTag" class="badge bg-secondary me-2">{{ collectionTag }}</span>
          </div>
        </div>

        <!-- Image Fields -->
        <div class="form-group mb-3">
          <label for="baseImage" class="form-label">Base Image</label>
          <input type="file" @change="handleBaseImageUpload" class="form-control form-control-lg" required>
        </div>
        <div class="form-group mb-3">
          <label for="cutoutShape" class="form-label">Cutout Shape (with multiply blend mode)</label>
          <input type="file" @change="handleCutoutShapeUpload" class="form-control form-control-lg" required>
        </div>
        <div class="form-group mb-3">
          <label for="design" class="form-label">Design/Text Image (optional)</label>
          <input type="file" @change="handleDesignUpload" class="form-control form-control-lg">
        </div>

        <div class="text-center">
          <button type="submit" class="btn btn-lg btn-primary shadow">Add Product</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { collection, addDoc } from 'firebase/firestore';
import { db, storage } from '@/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { mapActions } from 'vuex';


export default {
  name: "AddProductModal",
  data() {
    return {
      newProduct: {
        name: '',
        description: '',
        price: null,
        stock: null,
        tags: [],
        collectionTags: [],
        images: {
          baseImage: '',
          cutoutShape: '',
          design: ''
        }
      },
      newTag: '',
      newCollectionTag: '',
      baseImageFile: null,
      cutoutShapeFile: null,
      designFile: null,
      baseImagePreview: null,
      cutoutShapePreview: null,
      designPreview: null,
    };
  },
  methods: {
    ...mapActions(['triggerNotifications']),

    addTag() {
      if (this.newTag.trim() && !this.newProduct.tags.includes(this.newTag.trim())) {
        this.newProduct.tags.push(this.newTag.trim());
        this.newTag = ''; // Reset input after adding tag
      }
    },
    addCollectionTag() {
      if (this.newCollectionTag.trim() && !this.newProduct.collectionTags.includes(this.newCollectionTag.trim())) {
        this.newProduct.collectionTags.push(this.newCollectionTag.trim());
        this.newCollectionTag = ''; // Reset input after adding collection tag
      }
    },
    
    handleBaseImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.baseImageFile = file;
        this.baseImagePreview = URL.createObjectURL(file);
      } else {
        this.baseImagePreview = null;
        this.baseImageFile = null;
      }
    },
    
    handleCutoutShapeUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.cutoutShapeFile = file;
        this.cutoutShapePreview = URL.createObjectURL(file);
      } else {
        this.cutoutShapePreview = null;
        this.cutoutShapeFile = null;
      }
    },

    handleDesignUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.designFile = file;
        this.designPreview = URL.createObjectURL(file);
      } else {
        this.designPreview = null;
        this.designFile = null;
      }
    },

    async uploadImage(file, productId, imageType) {
      const imageRef = ref(storage, `products/${productId}/${imageType}`);
      const snapshot = await uploadBytes(imageRef, file);
      return await getDownloadURL(snapshot.ref);
    },

    async addProduct() {
      try {
        const productId = this.newProduct.name.toLowerCase().replace(/\s+/g, '-') || Date.now();

        // Upload Base Image
        if (this.baseImageFile) {
          this.newProduct.images.baseImage = await this.uploadImage(this.baseImageFile, productId, 'baseshirt.png');
        }

        // Upload Cutout Shape
        if (this.cutoutShapeFile) {
          this.newProduct.images.cutoutShape = await this.uploadImage(this.cutoutShapeFile, productId, 'outline.svg');
        }

        // Upload Design Image (if provided)
        if (this.designFile) {
          this.newProduct.images.design = await this.uploadImage(this.designFile, productId, 'design.png');
        }

        await addDoc(collection(db, 'products'), this.newProduct);

        this.resetForm();
        this.triggerNotifications({
          message: 'Product added successfully!',
          type: 'success',
          duration: 3000
        });
      } catch (error) {
        console.error('Error adding product:', error);
        this.triggerNotifications({
          message: 'Error adding product. Please try again.',
          type: 'error',
          duration: 5000
        });
      }
    },

    resetForm() {
      this.newProduct = { name: '', description: '', price: null, stock: null, tags: [], collectionTags: [], images: {} };
      this.baseImagePreview = null;
      this.cutoutShapePreview = null;
      this.designPreview = null;
    }
  }
};
</script>
<style src="@/assets/admin.css"></style>
<style scoped>
.container {
  max-width: 100%;
}
.selected-tags {
  display: flex;
  flex-wrap: wrap;
}

.badge {
  margin-bottom: 5px;
}

.image-preview-container {
  display: flex;
  flex-direction: column;
}

.layer-preview-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.image-preview-wrapper {
  position: relative;
  width: 300px;
  height: 300px;
  border: 2px solid #ddd;
}

.preview-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlay {
  opacity: 0.7;
}
</style>
