// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { ref, uploadBytes } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDZDXL3ULc_nebW2jGt0vjMn-ruBYpRXJM",
  authDomain: "tintly-backend.firebaseapp.com",
  databaseURL: "https://tintly-backend-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tintly-backend",
  storageBucket: "tintly-backend.appspot.com",
  messagingSenderId: "100363208020",
  appId: "1:100363208020:web:d6fc15b48b182f585cb4c1",
  measurementId: "G-PNVYMBPLGK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export async function uploadToFirestore(allowedConfig) {
  // Create a reference to the file in Firebase storage
  const fileRef = ref(storage, 'fashionfig-allowed.json');
  
  // Convert the allowedConfig to a Blob or JSON string
  const blob = new Blob([JSON.stringify(allowedConfig)], { type: 'application/json' });

  try {
    // Use uploadBytes to upload the file
    await uploadBytes(fileRef, blob);
    console.log('fashionfig-allowed.json uploaded successfully');
  } catch (error) {
    console.error('Error uploading fashionfig-allowed.json:', error);
  }
}

export { auth, db, storage };