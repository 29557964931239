<template>
    <div class="upload-button-container">
      <!-- Hidden input for image upload -->
      <input type="file" ref="fileInput" accept="image/*" @change="handleImageUpload" style="display: none" />
  
      <!-- Sticky upload button -->
      <button class="upload-button" @click="triggerUpload">
        Upload Image
      </button>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      // Trigger the hidden file input when the button is clicked
      triggerUpload() {
        this.$refs.fileInput.click();
      },
  
      // Handle the image upload and emit the file data to the parent component
      handleImageUpload(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const imageData = e.target.result;
            this.$emit("image-uploaded", imageData); // Emit the image data to parent
          };
          reader.readAsDataURL(file); // Convert image file to base64 string
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .upload-button-container {
    position: fixed;
    bottom: 13%;
    right: 20px;
    z-index: 1000;
  }
  
  .upload-button {
    background-color: #ff5a5f;
    color: white;
    border: none;
    padding: 15px 20px;
    border-radius: 50px;
    font-size: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    cursor: pointer;
  }
  
  .upload-button:hover {
    background-color: #ff3e3e;
  }
  </style>
  