<template>
        <!-- Top Header with Logo -->
        <header class="top-header">
      <img class="logo" src="../../assets/logo.svg" alt="" srcset="">
    </header>
    <div class="container main-view">
      <!-- Hero Banner Block -->
    <HeroBanner :banners="banners" :isScrollable="false" />
  <div class="signin-container">
    <h1>Sign In</h1>
    <p class="logininfo">Please Login to your Tintly account</p>
    <form @submit.prevent="signIn">
      <div class="input-group">
        <input type="email" placeholder="Email" v-model="email" required>
      </div>
      <div class="input-group">
        <input type="password" placeholder="Password" v-model="password" required>
      </div>
      <button type="submit" class="primary-button">Sign In</button>
    </form>
  </div>
</div>
</template>

<script>
import { auth } from '@/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import HeroBanner from '../uiblocks/HeroBanner.vue';
import { useRouter } from 'vue-router';
import { mapActions } from 'vuex';
import banner1 from "@/assets/hompagebanner/banner1.png";
export default {
  components: {
      HeroBanner
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      email: '',
      password: '',
      banners: [banner1]
    };
  },
  methods: {
  ...mapActions(['triggerNotification']),
  async signIn() {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
      const user = userCredential.user;
      console.log('User signed in:', user);
      this.triggerNotification({
          message: 'Login successful!',
          type: 'success',
          duration: 3000 // Notification will show for 3 seconds
        });
      // Redirect to the original intended route or the home page
      const redirect = this.$route.query.redirect || '/home/explore';
      this.router.push(redirect);
    } catch (error) {
      this.triggerNotification({
          message: 'Login Failed, Please Check Your Credentials!',
          type: 'error',
          duration: 3000 // Notification will show for 3 seconds
        });
    }
  }
}
}
</script>

<style scoped>
.signin-container {
  max-width: 300px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5%;
  text-align: center;
}
.top-header {
  display: flex;
  justify-content: center;
  align-content: center;
}
.logo {
  font-size: medium;
  width: 25vw;
  margin: 15px;
  top: 50%;
}
h1,h2,h3 {
  color: black;
  font-size: 35px;
}
.container {
  height: 100%;
  display: block;
  padding: 0;
  top: 35% !important;
  margin-top: 25%;
  margin-bottom: 15%;
}
h1, p {
  padding-top: 0;
  padding: 5px;
  margin: 0;
}
.logininfo {
  font-size: medium;
  text-align: center;
  text-justify: distribute;
}
.primary-button {
  padding: 10px 20px;
  background-color: #6a329f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.primary-button:hover {
  background-color: #5a297f;
}

.hero-banner {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: block;
  }
  
  .single-banner img {
    width: 100%;
    height: 25vh;
    image-rendering: optimizeSpeed;
    object-fit: contain !important;
    overflow: hidden;
    position: relative;
  }
</style>
