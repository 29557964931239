<template>
      <!-- Top Header with Logo -->
      <header class="top-header">
      <h1 class="logo"><img src="../../assets/logo.svg" alt="" srcset=""></h1>
    </header>
 <div class="container main-view">
    <p class="intro-text">Before we begin, tell us more about yourself</p>
    <form @submit.prevent="signUp">
      <div class="input-group">
        <input type="text" placeholder="Your name..." v-model="name" required>
      </div>
      <div class="input-group">
        <input type="text" placeholder="Your age" v-model="age" required>
      </div>
      <div class="input-group">
        <input type="email" placeholder="Your email" v-model="email" required>
      </div>
      <div class="input-group">
        <input type="password" placeholder="Create a password" v-model="password" required>
      </div>
      <p class="privacy-text">
        Shh! we keep your data safe (it’s a promise)
        <a href="#">Read more about privacy policy</a>
      </p>
      <button type="submit" class="button-primary">Let me in!</button>
    </form>
    </div>
  
    <!-- Include SuccessModal component in the template -->
    <SuccessModal 
      :visible="showModal" 
      :message="'Signup successful! Welcome to Tintly.'" 
      @close="showModal = false" 
    />
</template>



<script>
import { auth, db } from '@/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import SuccessModal from './SuccessModal.vue'; // Importing the SuccessModal component
import { useRouter } from 'vue-router';
import { mapActions } from 'vuex';
export default {
  components: {
    SuccessModal // Registering the SuccessModal component
  },
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      displayName: '',
      email: '',
      password: '',
      showModal: false // Control for showing the modal
    };
  },
  methods: {
    ...mapActions(['triggerNotification']),
    async signUp() {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
      await updateProfile(userCredential.user, {
        displayName: this.displayName
      });

            // Store additional user data in Firestore
            await setDoc(doc(db, "users", userCredential.user.uid), {
        username: this.displayName,
        user_email: this.email,
        user_age: '', // You can set this later
        user_gender: '', // You can set this later
        user_address: '', // You can set this later
        user_phonenumber: '' // You can set this later
      });

        this.showModal = true; // Show the success modal
        // Optionally redirect after showing the modal
        setTimeout(() => {
          this.showModal = false;
          this.router.push({ name: 'UserDetails', params: { userId: userCredential.user.uid } });
        }, 3000); // Close modal after 3 seconds
      } catch (error) {
        this.triggerNotification({
          message: 'Login Failed, Please Check Your Credentials!',
          type: 'error',
          duration: 3000 // Notification will show for 3 seconds
        });
      }
    }
  }
}
</script>

<style scoped>
/* Add your CSS here */
</style>
