import { createStore } from 'vuex';

export default createStore({
  state: {
    notification: {
      message: '',
      type: 'success',
      visible: false,
    },
  },
  mutations: {
    SHOW_NOTIFICATION(state, payload) {
      state.notification = { ...payload, visible: true };
    },
    HIDE_NOTIFICATION(state) {
      state.notification.visible = false;
    },
  },
  actions: {
    triggerNotification({ commit }, payload) {
      commit('SHOW_NOTIFICATION', payload);
      setTimeout(() => {
        commit('HIDE_NOTIFICATION');
      }, payload.duration || 3000); // Default duration is 3 seconds
    }
  }
});
