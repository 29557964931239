<template>
  <div class="container-fluid full-height">
    <div class="row full-height">
      <!-- Left Sidebar -->
      <div class="col-md-3 sidebar scrollable">
        <h4 class="text-center my-4">Product List</h4>
        <ul class="product-list">
          <li 
            v-for="product in filteredProducts" 
            :key="product.id" 
            @click="selectProduct(product.id)" 
            :class="{ 'selected': selectedProduct?.id === product.id }"
            class="product-item"
          >
            <div class="product-thumbnail">
              <img v-if="product.images.baseImage" :src="product.images.baseImage" alt="Product Thumbnail" class="img-fluid" />
              <div v-else class="product-placeholder">
                <span>{{ getInitials(product.name) }}</span>
              </div>
            </div>
            <span>{{ product.name }}</span>
          </li>
        </ul>
      </div>

      <!-- Right Editor Panel -->
      <div class="col-md-9 editor-panel">
        <!-- Fixed Top Nav Bar -->
        <div class="top-nav-bar">
          <div class="d-flex justify-content-end">
            <button class="btn btn-danger me-2" @click="deleteProduct(selectedProduct.id)" :disabled="!selectedProduct">Delete Product</button>
            <button class="btn btn-primary" @click="saveProduct(selectedProduct)" :disabled="!selectedProduct">Save Changes</button>
          </div>
        </div>

        <div class="scrollable editor-content">
          <div v-if="selectedProduct" class="editor-section">
            <h4 class="text-center mb-4">Edit Product</h4>
            
            <!-- Product Name -->
            <label class="form-label">Product Name</label>
            <input type="text" class="form-control mb-4" v-model="selectedProduct.name" />

            <!-- Product Description -->
            <label class="form-label">Product Description</label>
            <textarea class="form-control mb-4" rows="5" v-model="selectedProduct.description"></textarea>

            <!-- Product Price and Stock -->
            <div class="row">
              <div class="col-md-6">
                <label class="form-label">Price</label>
                <input type="number" class="form-control mb-4" v-model="selectedProduct.price" />
              </div>
              <div class="col-md-6">
                <label class="form-label">Stock</label>
                <input type="number" class="form-control mb-4" v-model="selectedProduct.stock" />
              </div>
            </div>

            <!-- Product Tags -->
            <label class="form-label">Tags</label>
            <input type="text" class="form-control mb-4" v-model="newTag" @keyup.enter="addTag(selectedProduct)" />
            <div class="selected-tags mt-2 mb-4">
              <span v-for="(tag, index) in selectedProduct.tags" :key="tag" class="badge bg-primary me-2" @click="removeTag(selectedProduct, index)">
                {{ tag }}
              </span>
            </div>

            <!-- Product Images -->
            <label class="form-label">Product Images</label>
            <div class="row mt-2">
              <div class="col-md-4 text-center">
                <h6>Base Image</h6>
                <img v-if="selectedProduct.images.baseImage" :src="selectedProduct.images.baseImage" alt="Base Image" class="img-fluid mb-2 image-preview" />
                <div v-else class="image-placeholder">B</div>
                <input type="file" class="form-control" @change="uploadImage($event, 'baseImage')" />
              </div>
              <div class="col-md-4 text-center">
                <h6>Cutout Shape</h6>
                <img v-if="selectedProduct.images.cutoutShape" :src="selectedProduct.images.cutoutShape" alt="Cutout Shape" class="img-fluid mb-2 image-preview" />
                <div v-else class="image-placeholder">C</div>
                <input type="file" class="form-control" @change="uploadImage($event, 'cutoutShape')" />
              </div>
              <div class="col-md-4 text-center">
                <h6>Design Image</h6>
                <img v-if="selectedProduct.images.design" :src="selectedProduct.images.design" alt="Design Image" class="img-fluid mb-2 image-preview" />
                <div v-else class="image-placeholder">D</div>
                <input type="file" class="form-control" @change="uploadImage($event, 'design')" />
              </div>
            </div>
          </div>

          <!-- Fallback if no product is selected -->
          <div v-else class="no-selection text-center">
            <h4>Select a product to edit</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import { collection, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage methods
import { db, storage } from '@/firebase'; // Firebase initialization
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      products: [],
      selectedProduct: null,
      newTag: '', // Used for adding tags
    };
  },
  async created() {
    await this.fetchProducts();
  },
  computed: {
    filteredProducts() {
      // Here you can filter based on your requirements
      return this.products;
    },
  },
  methods: {
    ...mapActions(['triggerNotification']), // Use VueX notification system

    async fetchProducts() {
      const querySnapshot = await getDocs(collection(db, 'products'));
      this.products = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    },

    selectProduct(productId) {
      this.selectedProduct = this.products.find(product => product.id === productId);
    },

    addTag(product) {
      if (this.newTag.trim() && !product.tags.includes(this.newTag)) {
        product.tags.push(this.newTag);
        this.newTag = '';
      }
    },

    removeTag(product, index) {
      product.tags.splice(index, 1);
    },

    async saveProduct(product) {
      try {
        await updateDoc(doc(db, 'products', product.id), product);
        this.triggerNotification({ message: 'Product updated successfully!', type: 'success', duration: 3000 });
      } catch (error) {
        this.triggerNotification({ message: 'Error updating product', type: 'error', duration: 5000 });
      }
    },

    async deleteProduct(productId) {
      try {
        await deleteDoc(doc(db, 'products', productId));
        this.triggerNotification({ message: 'Product deleted successfully', type: 'success', duration: 3000 });
        this.selectedProduct = null; // Deselect the product after deletion
        this.fetchProducts();
      } catch (error) {
        this.triggerNotification({ message: 'Error deleting product', type: 'error', duration: 5000 });
      }
    },

    async uploadImage(event, imageType) {
      const file = event.target.files[0];
      if (file && this.selectedProduct) {
        try {
          const storageRef = ref(storage, `products/${this.selectedProduct.id}/${imageType}`);
          await uploadBytes(storageRef, file);
          const downloadURL = await getDownloadURL(storageRef);

          this.selectedProduct.images[imageType] = downloadURL; // Update the selected product's image URL
          this.triggerNotification({ message: `${imageType} image uploaded successfully!`, type: 'success', duration: 3000 });
        } catch (error) {
          this.triggerNotification({ message: `Error uploading ${imageType} image`, type: 'error', duration: 5000 });
        }
      }
    },

    getInitials(productName) {
      if (!productName) return 'P';
      return productName
        .split(' ')
        .map(word => word[0])
        .join('');
    },
  },
};
</script>

<style scoped>
.container-fluid {
  overflow: hidden !important; 
}
body {
  overflow: hidden;
}
/* Ensure the entire viewport height is used */
.full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.scrollable {
  height: calc(100vh - 56px); /* Adjust this value if there's a navbar */
  overflow-y: auto;
}

/* Style for the top fixed navigation bar */
.top-nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 25%; /* Adjust this value to match the width of your sidebar */
  z-index: 10;
  background-color: #f8f9fa;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sidebar {
  background-color: #f8f9fa;
  height: 100%;
  border-right: 1px solid #ddd;
  padding: 0;
}

.product-list {
  list-style: none;
  padding: 0;
}

.product-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-item:hover {
  background-color: #e9ecef;
}

.product-thumbnail {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.product-placeholder {
  width: 50px;
  height: 50px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #666;
  border-radius: 5px;
}

.selected {
  background-color: #007bff;
  color: white;
}

.editor-panel {
  background-color: #fff;
  padding: 30px;
  height: 100%;
  overflow-y: auto;
  margin-top: 56px; /* Add margin to push content below the top navbar */
}

.editor-content {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.form-label {
  font-weight: bold;
  color: #555;
}

.image-placeholder {
  width: 100px;
  height: 100px;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: #666;
  border-radius: 8px;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}

.no-selection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
}

.selected-tags {
  display: flex;
  flex-wrap: wrap;
}

.badge {
  margin-bottom: 5px;
  cursor: pointer;
}

</style>
