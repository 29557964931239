<template>
    <div v-if="notification.visible" class="notification-container">
      <div :class="['notification', notification.type]" @click="hideNotification">
        <p>{{ notification.message }}</p>
        <span class="close-button" @click="hideNotification">&times;</span>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    name: "NotificationPane",
    computed: {
      ...mapState({
        notification: (state) => state.notification // Map the notification state from Vuex
      })
    },
    methods: {
      ...mapActions(['triggerNotification']),
      hideNotification() {
        this.triggerNotification({ visible: false });
      }
    }
  };
  </script>
  
  <style scoped>
  .notification-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999;
  }
  
  .notification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    min-width: 250px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .success {
    background-color: #28a745;
  }
  
  .error {
    background-color: #dc3545;
  }
  
  .info {
    background-color: #007bff;
  }
  
  .close-button {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 15px;
  }
  </style>
  