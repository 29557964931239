<template>
    <div class="manage-blog">
      <h1>Manage Blogs</h1>
  
      <!-- Blog List -->
      <table class="table mt-4">
        <thead>
          <tr>
            <th>Title</th>
            <th>Author</th>
            <th>Created On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="blog in blogs" :key="blog.id">
            <td>{{ blog.title }}</td>
            <td>{{ blog.author }}</td>
            <td>{{ formatDate(blog.createdOn) }}</td>
            <td>
              <button class="btn btn-secondary me-2" @click="editBlog(blog.id)">Edit</button>
              <button class="btn btn-danger" @click="deleteBlog(blog.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- WYSIWYG Blog Editor (Reuse the same WYSIWYG editor from blog creation) -->
      <div v-if="isEditing" class="mt-5">
        <h2>Edit Blog</h2>
        <div ref="editor" class="editor"></div>
        <button class="btn btn-primary mt-3" @click="saveBlog">Save Changes</button>
        <button class="btn btn-secondary mt-3 ms-2" @click="cancelEdit">Cancel</button>
      </div>
    </div>
  </template>
  
  <script>
  import { collection, getDocs, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
  import { db } from '@/firebase'; // Firebase setup
  import Quill from 'quill'; // Import Quill for WYSIWYG
  
  export default {
    data() {
      return {
        blogs: [], // Store list of blogs
        isEditing: false, // Track if editing mode is active
        editingBlogId: null, // The ID of the blog being edited
        blogTitle: '',
        author: '',
        content: '',
        quill: null, // Quill editor instance
      };
    },
    async created() {
      await this.fetchBlogs(); // Fetch blogs when the component is created
    },
    methods: {
      // Fetch all blogs from Firestore
      async fetchBlogs() {
        const querySnapshot = await getDocs(collection(db, 'blogs'));
        this.blogs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      },
  
      formatDate(timestamp) {
  if (timestamp instanceof Date) {
    // If it's a regular JavaScript Date object
    return timestamp.toLocaleDateString();
  } else if (timestamp.seconds) {
    // If it's a Firestore Timestamp object
    return new Date(timestamp.seconds * 1000).toLocaleDateString();
  } else {
    // Otherwise, assume it's a string and return it as is
    return timestamp;
  }
},
  
      // Edit a blog: Load blog data into the editor
      async editBlog(blogId) {
        this.isEditing = true;
        this.editingBlogId = blogId;
        
        const blogDoc = await getDoc(doc(db, 'blogs', blogId));
        const blogData = blogDoc.data();
  
        this.blogTitle = blogData.title;
        this.author = blogData.author;
        this.content = blogData.content;
  
        // Initialize the Quill editor if not already initialized
        if (!this.quill) {
          this.quill = new Quill(this.$refs.editor, {
            theme: 'snow',
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline'],
                ['image'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean'],
              ],
            },
          });
        }
  
        // Set the content in the editor
        this.quill.root.innerHTML = this.content;
      },
  
      // Save changes to the edited blog
      async saveBlog() {
        const content = this.quill.root.innerHTML; // Get the updated content from Quill
  
        await updateDoc(doc(db, 'blogs', this.editingBlogId), {
          title: this.blogTitle,
          author: this.author,
          content,
        });
  
        alert('Blog updated successfully!');
        this.isEditing = false;
        await this.fetchBlogs(); // Refresh the blog list
      },
  
      // Cancel editing
      cancelEdit() {
        this.isEditing = false;
        this.blogTitle = '';
        this.author = '';
        this.content = '';
        this.editingBlogId = null;
      },
  
      // Delete a blog from Firestore
      async deleteBlog(blogId) {
        if (confirm('Are you sure you want to delete this blog?')) {
          await deleteDoc(doc(db, 'blogs', blogId));
          alert('Blog deleted successfully!');
          await this.fetchBlogs(); // Refresh the blog list
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .editor {
    height: 300px;
    border: 1px solid #ccc;
  }
  </style>
  