<template>
  <div class="admin-container">
    <!-- Sidebar -->
<!-- Sidebar -->
<nav class="admin-sidebar">
  <ul>
    <li><router-link to="/admin/dashboard/">Dashboard</router-link></li>
    <li><router-link to="/admin/dashboard/add-product">Add Product</router-link></li>
    <li><router-link to="/admin/dashboard/manage-product">Manage Products</router-link></li>
    <li><router-link to="/admin/dashboard/bulk-upload">Upload Bulk</router-link></li>
    <li><router-link to="/admin/dashboard/banner-upload">Banner Upload</router-link></li> <!-- Added this line -->
    <li><router-link to="/admin/dashboard/manage-collection">Collections</router-link></li> <!-- Added this line -->
    <li><router-link to="/admin/dashboard/manage-blog">Create New Blog</router-link></li> <!-- Added this line -->
    <li><router-link to="/admin/dashboard/edit-blog">Edit Blogs</router-link></li> <!-- Added this line -->
    <li><router-link to="/admin/dashboard/manage-page">Manage Pages</router-link></li> <!-- Added this line -->
    <li><router-link to="/admin/dashboard/configure-avatar">Avatar Configurator</router-link></li> <!-- Added this line -->
    <li><router-link to="/editorx/">EditorX</router-link></li>
  </ul>
</nav>


    <!-- Main Content -->
    <div class="admin-content">
      <router-view />
    </div>
  </div>
</template>
<style scoped>

.admin-sidebar {
  position: fixed; /* Make it fixed */
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh; /* Full height */
  background-color: #343a40;
  padding-top: 20px;
  color: white;
  overflow: auto; /* Ensure scrolling if content overflows */
}

.admin-sidebar ul {
  list-style: none;
  padding-left: 0;
}

.admin-sidebar li {
  margin: 10px 0;
}

.admin-sidebar a {
  color: white;
  text-decoration: none;
  padding: 10px;
  display: block;
}

.admin-content {
  margin-left: 220px; /* Make space for the sidebar */
  padding: 20px;
  width: calc(100% - 220px); /* Ensure content takes up remaining space */
}

.admin-content > * {
  margin-top: 20px;
}
</style>
