<template>
    <div>
      <h2>Orders Page</h2>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrdersView'
  }
  </script>
