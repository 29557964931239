<template>
    <div class="container mt-4 bulk-upload">
      <h2 class="text-center text-primary mb-4">Bulk Upload Products</h2>
  
      <!-- CSV Upload Section -->
      <div class="mb-3">
        <label for="csvFile" class="form-label">Upload CSV</label>
        <input type="file" class="form-control" id="csvFile" @change="handleFileUpload" accept=".csv" />
      </div>
  
      <!-- Product Preview Section -->
      <div v-if="parsedProducts.length">
        <h3 class="mt-4">Parsed Products</h3>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Product ID</th>
              <th>Product Name</th>
              <th>Sizes</th>
              <th>Base Price</th>
              <th>Selling Price</th>
              <th>Colors (Hex)</th>
              <th>Base Image</th>
              <th>Cutout Shape</th>
              <th>Design Image</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in parsedProducts" :key="product.ID">
              <td>{{ product.ID }}</td>
              <td>{{ product['Product Description'] }}</td>
              <td>{{ product.sizes }}</td>
              <td>{{ product['Base Price'] }}</td>
              <td>{{ product['Selling Price'] }}</td>
              <td>{{ product.HexColors.join(', ') }}</td>
              <td>
                <input type="file" class="form-control" @change="previewImage($event, product, 'baseImageFile')" />
                <img :src="product.baseImagePreview" v-if="product.baseImagePreview" alt="Base Image Preview" class="img-fluid mt-2" />
              </td>
              <td>
                <input type="file" class="form-control" @change="previewImage($event, product, 'cutoutShapeFile')" />
                <img :src="product.cutoutShapePreview" v-if="product.cutoutShapePreview" alt="Cutout Shape Preview" class="img-fluid mt-2" />
              </td>
              <td>
                <input type="file" class="form-control" @change="previewImage($event, product, 'designFile')" />
                <img :src="product.designPreview" v-if="product.designPreview" alt="Design Image Preview" class="img-fluid mt-2" />
              </td>
            </tr>
          </tbody>
        </table>
  
        <!-- Upload Button -->
        <button class="btn btn-primary mt-4" @click="submitBulkUpload">Upload Products</button>
      </div>
    </div>
  </template>
  
  <script>
  import Papa from 'papaparse';
  import { mapActions } from 'vuex';
  import { storage, db } from '@/firebase';  // Import Firebase storage and Firestore
  import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
  import { doc, setDoc } from 'firebase/firestore';
  
  export default {
    data() {
      return {
        parsedProducts: [],
        newTag: '',
        newCollectionTag: '',
        colorHexMap: {
          "Maroon": "#2D0101",
          "Orange": "#E65E00",
          "Flag Green": "#159512",
          "Pink": "#CC2867",
          "Bottle Green": "#22482E",
          "Royal Blue": "#1F286A",
          "Purple": "#321541",
          "Red": "#A50303",
          "Yellow": "#F9D168",
          "Golden Yellow": "#EF9A31",
          "New Yellow": "#FCFA30",
          "Sky Blue": "#19E4FF",
          "Light Pink": "#FFD5DB",
          "Charcoal Melange": "#6E6E6E",
          "Grey Melange": "#C3C3C3",
          "Navy Blue": "#2D314A",
          "White": "#FFFFFF",
          "Brick Red": "#7B2F1D",
          "Coffee Brown": "#1C100F",
          "Olive Green": "#453E2F",
          "Petrol Blue": "#002A2F",
          "Steel Grey": "#3A3E41",
          "Mustard Yellow": "#CF8F26",
          "Black": "#151515",
          "Beige": "#EBCD8B",
          "Lavender": "#BBB1D2",
          "Baby Blue": "#A4CEF8",
          "Khaki": "#9D6333",
          "Mint": "#BFFCF7",
          "Coral": "#C86E4E",
          "Flamingo": "#E29891",
          "Mushroom": "#CC9D93",
          "Jade": "#CCF5C9",
          "Copper": "#C2745F",
          "Peach": "#FFDEC6"
        }
      };
    },
    methods: {
      ...mapActions(['triggerNotification']),  // Use VueX notification system
  
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          Papa.parse(file, {
            header: true,
            complete: (results) => {
              this.parsedProducts = results.data.map(product => ({
                ...product,
                tags: [],
                collectionTags: [],
                baseImageFile: null,
                cutoutShapeFile: null,
                designFile: null,
                baseImagePreview: null,
                cutoutShapePreview: null,
                designPreview: null,
                HexColors: this.mapColorsToHex(product.Colors),
              }));
              this.triggerNotification({
                message: 'CSV File Parsed Successfully',
                type: 'success',
                duration: 3000,
              });
            },
          });
        }
      },
  
      // Map color names to hex codes
      mapColorsToHex(colorString) {
        const colors = colorString.split(',').map(c => c.trim());
        return colors.map(color => this.colorHexMap[color] || "#000000");
      },
  
      previewImage(event, product, fileType) {
        const file = event.target.files[0];
        if (file) {
          product[fileType] = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            product[`${fileType.replace('File', 'Preview')}`] = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
  
      async submitBulkUpload() {
        for (const product of this.parsedProducts) {
          try {
            const baseImageURL = await this.uploadImage(product.baseImageFile, product.ID, 'baseImage');
            const cutoutShapeURL = await this.uploadImage(product.cutoutShapeFile, product.ID, 'cutoutShape');
            const designURL = await this.uploadImage(product.designFile, product.ID, 'design');
  
            await setDoc(doc(db, 'products', product.ID), {
              name: product['Product Description'],
              sizes: product.sizes,
              price: product['Base Price'],
              sellingPrice: product['Selling Price'],
              colors: product.HexColors,
              tags: product.tags,
              collectionTags: product.collectionTags,
              images: {
                baseImage: baseImageURL,
                cutoutShape: cutoutShapeURL,
                design: designURL,
              },
            });
  
            this.triggerNotification({
              message: `Product ${product['Product Description']} uploaded successfully!`,
              type: 'success',
              duration: 3000,
            });
          } catch (error) {
            console.error('Error uploading product:', error);
            this.triggerNotification({
              message: `Error uploading product ${product['Product Description']}.`,
              type: 'error',
              duration: 5000,
            });
          }
        }
      },
  
      async uploadImage(file, productId, imageType) {
        if (file) {
          const storageRef = ref(storage, `products/${productId}/${imageType}`);
          await uploadBytes(storageRef, file);
          return await getDownloadURL(storageRef);  // Get the URL after upload
        }
        return '';
      },
    },
  };
  </script>
  
  <style scoped>
  .bulk-upload {
    margin-top: 20px;
  }
  
  .color-box {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #ddd;
    display: inline-block;
  }
  </style>
  