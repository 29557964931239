import { createRouter, createWebHistory } from 'vue-router';
import { getAuth } from 'firebase/auth';

import UserSignIn from '../components/web/UserSignIn.vue';
import UserDetails from '../components/web/UserDetails.vue';
import HomePage from '../components/web/HomePage.vue';
import Explore from '../components/web/Explore.vue';
import Orders from '../components/admin/Orders.vue';
import DefaultView from '../components/web/DefaultView.vue';
import AdminLogin from '../components/admin/AdminLogin.vue';
import AdminDefault from '../components/admin/AdminDefault.vue';
import AddProductModal from '@/components/admin/AddProductModal.vue';
import ManageProducts from '../components/admin/ManageProducts.vue';
import AdminDashboard from '@/components/admin/AdminDashboard.vue';
import BulkUpload from '@/components/admin/BulkUpload.vue';
import testerVue from '@/components/tester.vue';
import BannerUpload from '@/components/admin/BannerUpload.vue';
import ManageCollection from '@/components/admin/ManageCollection.vue';
import BlogManager from '@/components/admin/BlogManager.vue';
import EditBlog from '@/components/admin/EditBlog.vue';
import PageManager from '@/components/admin/PageManager.vue';
import AvatarConfigurator from '@/components/admin/AvatarConfigurator.vue';

const routes = [
    { path: '/', redirect: '/admin/login' },  // Redirect to DefaultView by default
    { path: '/default', name: 'DefaultView', component: DefaultView },
    { path: '/signin', name: 'SignIn', component: UserSignIn },
    { 
      path: '/home', 
      component: HomePage, 
      meta: { requiresAuth: true }, 
      children: [
        { path: '', redirect: 'explore' },
        { path: 'explore', name: 'Explore', component: Explore },
        { path: 'orders', name: 'Orders', component: Orders },
        { path: 'userdetails', name: 'UserDetails', component: UserDetails, props: true }
      ]
    },
  
    { path: '/admin/login', component: AdminLogin },
    {
      path: '/admin/dashboard',
      component: AdminDefault,
      meta: { requiresAuth: true, requiresAdmin: true }, // Protect the route
      children: [
        { path: '', name: 'AdminDashboard', component: AdminDashboard},
        { path: 'add-product', name: 'AddProduct', component: AddProductModal },
        { path: 'manage-product', name: 'ManageProduct', component: ManageProducts },
        { path: 'bulk-upload', name: 'BulkUpload', component: BulkUpload },
        { path: 'banner-upload', name: 'BannerUpload', component: BannerUpload },
        { path: 'manage-collection', name: 'ManageCollection', component: ManageCollection },
        { path: 'manage-blog', name: 'BlogManager', component: BlogManager },
        { path: 'edit-blog', name: 'EditBlog', component: EditBlog },
        { path: 'manage-page', name: 'ManagePage', component: PageManager },
        { path: 'configure-avatar', name: 'AvatarConfigurator', component: AvatarConfigurator },
      ]
    },
    { path: "/test/", component: testerVue},
    {
      path: '/customize-product/:productId',
      name: 'ProductsCustomize', // Route name should match exactly
      component: () => import('@/components/web/ProductCustomize.vue')
    },    
    {
      path: '/explore-products',
      name: 'ExploreProducts',
      component: () => import('@/components/web/ExploreProducts.vue')
    }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Global navigation guard
router.beforeEach(async (to, from, next) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  // Check if route requires authentication
  if (requiresAuth && !currentUser) {
    return next('/admin/login'); // Redirect to login if not authenticated
  }

  // If admin route, check if the user is an admin
  if (requiresAdmin) {
    const token = await currentUser.getIdTokenResult();
    if (!token.claims.admin) {
      return next('/home'); // Redirect non-admins to user home
    }
  }

  if (!to.path.startsWith('/') && !to.path.startsWith('/editorx')) {
    // If not admin, load global.css
  } else {
    // If admin, remove the global.css
    const globalStyles = document.querySelector(`link[href*="global.css"]`);
    if (globalStyles) {
      globalStyles.remove(); // Remove the global.css file
    }
  }

  // If the user is authenticated or no auth required, proceed
  next();
});

export default router;
