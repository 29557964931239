<template>
    <div class="banner-upload">
      <h1>Banner Management</h1>
  
      <!-- Banner Group Selection -->
      <form @submit.prevent="uploadBanners">
        <div class="mb-3">
          <label for="group">Select or Create Banner Group</label>
          <div class="input-group">
            <select class="form-select" v-model="selectedGroup" @change="loadGroupBanners">
              <option v-for="group in bannerGroups" :key="group.id" :value="group.id">{{ group.name }}</option>
            </select>
            <input type="text" class="form-control" placeholder="New Group Name" v-model="newGroupName" />
            <button class="btn btn-primary" type="button" @click="createNewGroup">Create Group</button>
          </div>
        </div>
  
        <!-- Banner Upload -->
        <div class="mb-3">
          <label for="banner">Choose Banners (Multiple)</label>
          <input type="file" id="banner" multiple @change="onFileSelected" class="form-control" />
        </div>
  
        <button type="submit" class="btn btn-success">Upload</button>
      </form>
  
      <!-- Visual Rearrangement of Banners -->
      <div v-if="banners.length" class="mt-4">
        <h2>Rearrange Banners</h2>
        <ul class="list-group">
          <li
            v-for="(banner, index) in banners"
            :key="index"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            <img :src="banner.url" alt="Banner Preview" class="banner-preview" />
            <div>
              <button class="btn btn-secondary" @click="moveBannerUp(index)" :disabled="index === 0">Move Up</button>
              <button class="btn btn-secondary" @click="moveBannerDown(index)" :disabled="index === banners.length - 1">Move Down</button>
            </div>
          </li>
        </ul>
        <button class="btn btn-primary mt-3" @click="saveBannerOrder">Save Order</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import { getFirestore, doc, setDoc, collection, getDocs, addDoc, getDoc } from "firebase/firestore"; // Firestore imports
  import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase Storage imports
  
  export default {
    setup() {
      const db = getFirestore();
      const storage = getStorage();
      const selectedGroup = ref("");
      const newGroupName = ref("");
      const bannerGroups = ref([]);
      const banners = ref([]);
      const selectedFiles = ref([]);
  
      // Fetch existing banner groups
      const fetchBannerGroups = async () => {
        const querySnapshot = await getDocs(collection(db, "bannerGroups"));
        bannerGroups.value = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      };
  
      // Load existing banners for a selected group
      const loadGroupBanners = async () => {
        if (!selectedGroup.value) return;
  
        const groupRef = doc(db, "bannerGroups", selectedGroup.value);
        const groupDoc = await getDoc(groupRef);
        if (groupDoc.exists()) {
          banners.value = groupDoc.data().banners || [];
        }
      };
  
      const onFileSelected = (event) => {
        selectedFiles.value = Array.from(event.target.files);
      };
  
      const uploadBanners = async () => {
        if (selectedFiles.value.length === 0 || (!selectedGroup.value && !newGroupName.value)) {
          alert("Please select or create a group and choose banners.");
          return;
        }
  
        // If a new group is being created
        let groupId = selectedGroup.value;
        if (newGroupName.value) {
          // Ensure the new group is added with the name and empty banners
          const docRef = await addDoc(collection(db, "bannerGroups"), {
            name: newGroupName.value,
            banners: [], // Initialize with an empty banners array
          });
          groupId = docRef.id;
          selectedGroup.value = groupId; // Set the selected group to the newly created group
          await fetchBannerGroups(); // Refresh banner groups
          newGroupName.value = ""; // Clear the new group name input
        }
  
        // Upload banners
        const groupRef = doc(db, "bannerGroups", groupId);
        for (const file of selectedFiles.value) {
          const fileRef = storageRef(storage, `banners/${groupId}/${file.name}`);
          const snapshot = await uploadBytes(fileRef, file);
          const downloadURL = await getDownloadURL(snapshot.ref);
  
          // Update the Firestore document with the new banner URL
          const groupDoc = await getDoc(groupRef);
          const bannersData = groupDoc.exists() ? groupDoc.data().banners : [];
  
          await setDoc(groupRef, {
            name: groupDoc.exists() ? groupDoc.data().name : newGroupName.value, // Ensure the group name is kept
            banners: [...bannersData, { url: downloadURL, order: bannersData.length + 1 }],
          });
        }
  
        // Reload banners for the group
        await loadGroupBanners();
        selectedFiles.value = [];
        alert("Banners uploaded successfully!");
      };
  
      const createNewGroup = async () => {
        if (!newGroupName.value) {
          alert("Please enter a new group name.");
          return;
        }
  
        // Create a new group with an empty banner array
        const docRef = await addDoc(collection(db, "bannerGroups"), {
          name: newGroupName.value,
          banners: [],
        });
        selectedGroup.value = docRef.id; // Set the new group as selected
        await fetchBannerGroups(); // Refresh the banner groups
        newGroupName.value = ""; // Clear the input field
        await loadGroupBanners(); // Load banners for the new group
      };
  
      // Save the new banner order after rearrangement
      const saveBannerOrder = async () => {
        const groupRef = doc(db, "bannerGroups", selectedGroup.value);
        await setDoc(groupRef, {
          name: bannerGroups.value.find((group) => group.id === selectedGroup.value)?.name || "", // Retain the group name
          banners: banners.value.map((banner, index) => ({ ...banner, order: index + 1 })),
        });
        alert("Banner order saved successfully!");
      };
  
      // Move a banner up
      const moveBannerUp = (index) => {
        if (index > 0) {
          const temp = banners.value[index];
          banners.value[index] = banners.value[index - 1];
          banners.value[index - 1] = temp;
        }
      };
  
      // Move a banner down
      const moveBannerDown = (index) => {
        if (index < banners.value.length - 1) {
          const temp = banners.value[index];
          banners.value[index] = banners.value[index + 1];
          banners.value[index + 1] = temp;
        }
      };
  
      onMounted(fetchBannerGroups); // Load banner groups on component mount
  
      return {
        selectedGroup,
        newGroupName,
        bannerGroups,
        onFileSelected,
        uploadBanners,
        banners,
        saveBannerOrder,
        createNewGroup,
        loadGroupBanners,
        moveBannerUp,
        moveBannerDown,
      };
    },
  };
  </script>
  
  

<style scoped>
 .banner-preview {
    aspect-ratio: 3 / 4;
    height: 250px;
    object-fit: cover;
 }
</style>