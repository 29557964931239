<template>
  <div class="configurator container">
    <h2 class="my-4">Avatar Allowance Configurator</h2>

    <div v-for="(filteredOptions, category) in filteredFashionfigLock" :key="category">
      <h3 class="my-3">{{ category }}</h3>
      <div class="row">
        <div
          class="col-4"
          v-for="option in filteredOptions"
          :key="option.name"
          @click="toggleOption(category, option.name)"
        >
          <div
            class="card option-card"
            :class="{'selected': allowedOptions[category][option.name]}"
          >
            <!-- Dynamic path that includes both the category and the file name -->
            <img :src="`/avatars/${category}/${option.file}`" class="card-img-top" alt="Avatar option" />
            <div class="card-body text-center">
              <h5 class="card-title">{{ option.name }}</h5>
              <p class="card-text">{{ option.file }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button class="btn btn-primary mt-4" @click="saveAllowedOptions">Save Allowed Options</button>
  </div>
</template>

<script>
import { uploadToFirestore } from '@/firebase';

export default {
  data() {
    return {
      fashionfigLock: {}, // Loaded from the JSON file
      allowedOptions: {},  // To store admin selections
    };
  },
  async mounted() {
    await this.loadFashionfigLock();
    await this.loadAllowedOptions(); // Load pre-existing allowed options
  },
  computed: {
    // Computed property to filter out unwanted files like '.DS_Store'
    filteredFashionfigLock() {
      const filteredLock = {};
      Object.keys(this.fashionfigLock).forEach(category => {
        filteredLock[category] = this.fashionfigLock[category].filter(option => option.file !== '.DS_Store');
      });
      return filteredLock;
    },
  },
  methods: {
    // Load the fashionfig-lock.json file
    async loadFashionfigLock() {
      try {
        const response = await fetch('/fashionfig-lock.json');  // Adjust the path
        const data = await response.json();
        this.fashionfigLock = data;

        // Initialize allowedOptions based on the categories in fashionfigLock
        this.allowedOptions = Object.keys(data).reduce((acc, category) => {
          acc[category] = {};
          data[category].forEach(option => {
            acc[category][option.name] = false; // Default to false (disabled)
          });
          return acc;
        }, {});
      } catch (error) {
        console.error('Error loading fashionfig-lock.json:', error);
      }
    },
    // Load pre-existing allowed options from fashionfig-allowed.json
    async loadAllowedOptions() {
      try {
        const response = await fetch('https://firebasestorage.googleapis.com/v0/b/tintly-backend.appspot.com/o/fashionfig-allowed.json?alt=media&token=30a85321-6f72-4ad8-abc2-afdcdf9bc333');  // Adjust the path
        const data = await response.json();

        // Merge the existing allowed options into the current allowedOptions
        Object.keys(data.allowedOptions).forEach(category => {
          Object.keys(data.allowedOptions[category]).forEach(optionName => {
            if (data.allowedOptions[category][optionName]) {
              this.allowedOptions[category][optionName] = true;
            }
          });
        });
      } catch (error) {
        console.error('Error loading fashionfig-allowed.json:', error);
        // If there's no existing allowed file, we proceed with the default false values.
      }
    },
    // Toggle selection
    toggleOption(category, optionName) {
      this.allowedOptions[category][optionName] = !this.allowedOptions[category][optionName];
    },
    // Save allowed options
    async saveAllowedOptions() {
      const allowedConfig = {
        allowedOptions: this.allowedOptions,
        timestamp: new Date().toISOString(),
      };

      // Save the allowedConfig to Firestore Storage (or another backend)
      console.log('Saving allowed options:', allowedConfig);

      // You can also call a Firebase function to upload this file
      await uploadToFirestore(allowedConfig);
    },
  },
};
</script>

<style scoped>
.configurator {
  padding: 20px;
}

.option-card {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
}

.option-card img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.option-card.selected {
  border: 2px solid #007bff;
  background-color: #e6f3ff;
}

.option-card:hover {
  transform: scale(1.05);
}

.card-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 0.85rem;
}
</style>
