<template>
  <div class="home-container">
    <!-- Top Header with Logo -->
    <header class="top-header">
      <img src="@/assets/logo.svg" class="logo" alt="Tintly Logo">
    </header>

    <!-- Main View Area -->
    <main class="main-view">
      <router-view></router-view> <!-- This will display the child components -->
    </main>

    <!-- Bottom Navigation -->
    <nav class="bottom-nav">
      <ul>
        <li @click="navigateTo('explore')" :class="{ active: activePage === 'explore' }">
          <img src="@/assets/icons/explore_svgrepo.com.svg" alt="Explore">
        </li>
        <li @click="navigateTo('orders')" :class="{ active: activePage === 'orders' }">
          <img src="@/assets/icons/order_svgrepo.com.svg" alt="Orders">
        </li>
        <li @click="navigateTo('userdetails')" :class="{ active: activePage === 'userdetails' }">
          <img src="@/assets/icons/user_svgrepo.com.svg" alt="User Details">
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';

export default {
  name: 'HomePage',
  setup() {
    const router = useRouter();
    const route = useRoute();
    const activePage = ref(route.name ? route.name.toLowerCase() : 'explore');

    const navigateTo = (page) => {
      activePage.value = page;
      router.push(`/home/${page}`); // Ensure it navigates to /home/<page>
    };

    return { activePage, navigateTo };
  }
}
</script>

<style scoped>
/* Container */
.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Top Header with Logo */
.top-header {
  background-color: #6a329f;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  height: 64px; /* Set a fixed height for the header */
}

.logo {
  max-height: 40px;
}

/* Main View Area */
.main-view {
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; /* Add padding for inner content */
  padding-top: 64px; /* Add padding equal to the height of the header to prevent overlap */
  overflow-y: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Bottom Navigation */
.bottom-nav {
  background-color: #6a329f;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1000;
  height: 60px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Elevation for bottom nav */
}

.bottom-nav ul {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 0;
  padding: 0;
  list-style: none;
}

.bottom-nav li {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.bottom-nav li:hover {
   /* Slight lift effect on hover */
}

.bottom-nav li.active {
  bottom: 0;
  width: 60px;
  height: 60px;
  background-color: #5a297f;
  color: white;
}

.bottom-nav li img {
  height: 30px;
  width: 30px;
  transition: filter 0.3s ease; /* Smooth transition for active icon */
}

.bottom-nav li.active img {
  filter: brightness(0) invert(1);
}

</style>
