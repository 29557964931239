import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { auth } from '@/firebase';
if (!window.location.pathname.startsWith('/admin')) {
  import('@/assets/global.css'); // Import global.css only for non-admin routes
}
// Import Bootstrap's CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Bootstrap's JS (including Popper.js)
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// Import the FontAwesome core
import { library } from '@fortawesome/fontawesome-svg-core';

// Import FontAwesome icon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// Import specific icons
import { faFont, faImage, faSquare } from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(faFont, faImage, faSquare);
// Import jQuery globally
import jQuery from 'jquery';
// Attach jQuery to the global window object
window.$ = window.jQuery = jQuery;
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { MotionPlugin } from '@vueuse/motion';
import BootstrapVueNext from 'bootstrap-vue-next';
import store from './store'
import VueKonva from 'vue-konva'


// Initialize the app immediately
createApp(App)
  .use(router)
  .use(BootstrapVueNext)
  .use(store).component('font-awesome-icon', FontAwesomeIcon)
  .use(MotionPlugin).use(VueKonva)
  .mount('#app');

// Handle Firebase authentication after the app is already mounted
auth.onAuthStateChanged(user => {
  if (user) {
    console.log('User logged in:', user);
  } else {
    console.log('No user logged in');
  }
});
