<template>
    <div class="page-manager">
      <h1>Manage Pages</h1>
  
      <!-- Page List -->
      <table class="table mt-4">
        <thead>
          <tr>
            <th>Title</th>
            <th>Created On</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="page in pages" :key="page.id">
            <td>{{ page.title }}</td>
            <td>{{ formatDate(page.createdOn) }}</td>
            <td>
              <button class="btn btn-secondary me-2" @click="editPage(page.id)">Edit</button>
              <button class="btn btn-danger" @click="deletePage(page.id)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
  
      <!-- WYSIWYG Page Editor -->
      <div v-if="isEditing || isCreating" class="mt-5">
        <h2>{{ isEditing ? 'Edit Page' : 'Create New Page' }}</h2>
        <div class="form-group mb-3">
          <label for="title">Page Title</label>
          <input type="text" id="title" v-model="pageTitle" class="form-control" />
        </div>
        <div ref="editor" class="editor"></div>
        <button class="btn btn-primary mt-3" @click="savePage">Save Page</button>
        <button class="btn btn-secondary mt-3 ms-2" @click="cancelEdit">Cancel</button>
      </div>
    </div>
  </template>
  
  <script>
  import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
  import { db } from '@/firebase'; // Firebase setup
  import Quill from 'quill'; // WYSIWYG Editor
  
  export default {
    data() {
      return {
        pages: [], // Store list of pages
        isEditing: false, // Track editing mode
        isCreating: false, // Track if creating a new page
        editingPageId: null, // ID of the page being edited
        pageTitle: '', // Page title
        quill: null, // Quill editor instance
        content: '', // Page content
      };
    },
    async created() {
      await this.fetchPages(); // Fetch pages when the component is created
    },
    methods: {
      // Fetch all pages from Firestore
      async fetchPages() {
        const querySnapshot = await getDocs(collection(db, 'pages'));
        this.pages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      },
  
      // Format the page creation date
      formatDate(timestamp) {
        if (timestamp instanceof Date) {
          return timestamp.toLocaleDateString();
        } else if (timestamp.seconds) {
          return new Date(timestamp.seconds * 1000).toLocaleDateString();
        } else {
          return timestamp;
        }
      },
  
      // Edit an existing page: Load data into the editor
      async editPage(pageId) {
        this.isEditing = true;
        this.isCreating = false;
        this.editingPageId = pageId;
        
        const pageDoc = await getDoc(doc(db, 'pages', pageId));
        const pageData = pageDoc.data();
  
        this.pageTitle = pageData.title;
        this.content = pageData.content;
  
        // Initialize the Quill editor if not already initialized
        if (!this.quill) {
          this.quill = new Quill(this.$refs.editor, {
            theme: 'snow',
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean'],
              ],
            },
          });
        }
  
        // Set the content in the editor
        this.quill.root.innerHTML = this.content;
      },
  
      // Create a new page
      createPage() {
        this.isCreating = true;
        this.isEditing = false;
        this.pageTitle = '';
        this.content = '';
        this.editingPageId = null;
  
        if (!this.quill) {
          this.quill = new Quill(this.$refs.editor, {
            theme: 'snow',
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean'],
              ],
            },
          });
        } else {
          this.quill.root.innerHTML = ''; // Clear the editor
        }
      },
  
      // Save the page (either create new or update existing)
      async savePage() {
        const content = this.quill.root.innerHTML; // Get the content from Quill
  
        if (this.isEditing) {
          // Update the existing page
          await updateDoc(doc(db, 'pages', this.editingPageId), {
            title: this.pageTitle,
            content,
          });
        } else {
          // Create a new page
          await addDoc(collection(db, 'pages'), {
            title: this.pageTitle,
            content,
            createdOn: new Date(),
          });
        }
  
        alert('Page saved successfully!');
        this.isEditing = false;
        this.isCreating = false;
        await this.fetchPages(); // Refresh the page list
      },
  
      // Cancel editing or creating
      cancelEdit() {
        this.isEditing = false;
        this.isCreating = false;
        this.pageTitle = '';
        this.content = '';
      },
  
      // Delete a page
      async deletePage(pageId) {
        if (confirm('Are you sure you want to delete this page?')) {
          await deleteDoc(doc(db, 'pages', pageId));
          alert('Page deleted successfully!');
          await this.fetchPages(); // Refresh the page list
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .editor {
    height: 300px;
    border: 1px solid #ccc;
  }
  </style>
  