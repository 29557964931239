<template>
  <div class="explore-page">
    <!-- Hero Banner Block -->
    <HeroBanner :banners="banners" :isScrollable="true" />

    <!-- Image Placeholder -->
    <div class="image-placeholder" v-if="uploadedImage">
      <img :src="uploadedImage" alt="Uploaded Image" class="uploaded-image" />
    </div>
    <div class="image-placeholder" v-else>
      <img src="../../assets/dummy.png" class="placeholder-img" alt="" srcset="">
    </div>

    <!-- Sticky Button for Image Upload -->
    <StickyImageUploadButton 
      v-if="!uploadedImage" 
      @image-uploaded="handleImageUpload" 
    />
    <button 
      v-if="uploadedImage" 
      class="proceed-button" 
      @click="proceedToProductList"
    >
      Proceed Next
    </button>

    <!-- Bottom Navigation Bar (Optional) -->
    <nav class="bottom-nav">
      <button class="nav-btn">
        <i class="fas fa-compass"></i>
      </button>
      <button class="nav-btn">
        <i class="fas fa-shopping-bag"></i>
      </button>
      <button class="nav-btn">
        <i class="fas fa-user"></i>
      </button>
    </nav>
  </div>
</template>

<script>
import HeroBanner from '@/components/uiblocks/HeroBanner.vue';
import StickyImageUploadButton from '@/components/uiblocks/StickyButtonUploader.vue';
import ColorThief from 'colorthief'; // Ensure Color Thief is installed and imported
import banner1 from '@/assets/hompagebanner/banner1.png';
import banner2 from '@/assets/hompagebanner/banner2.png';
import banner3 from '@/assets/hompagebanner/banner3.png';
export default {
  name: 'ExploreLandingPage',
  components: {
    HeroBanner,
    StickyImageUploadButton
  },
  data() {
    return {
      banners: [
        banner1,
        banner2,
        banner3
      ],
      uploadedImage: null, // Will hold the uploaded image URL
      colorPalette: [] // To store the extracted colors from the image
    }
  },
  methods: {
    handleImageUpload(imageData) {
  this.uploadedImage = imageData;

  // Extract the color palette using Color Thief
  const img = new Image();
  img.src = imageData;
  img.crossOrigin = "Anonymous"; // Avoid CORS issues

  img.onload = () => {
    const colorThief = new ColorThief();
    const palette = colorThief.getPalette(img, 5); // Extract top 5 colors
    this.colorPalette = palette.map(color => `rgb(${color.join(',')})`);

    // Log the extracted palette for debugging
    console.log("Extracted Color Palette:", this.colorPalette);

    // Now navigate to the product list page with the palette
    this.$router.push({
      name: 'ExploreProducts',
      query: {
        uploadedImage: this.uploadedImage,
        colorPalette: JSON.stringify(this.colorPalette) // Pass the color palette
      }
    });
  };
},
  proceedToProductList() {
    // Navigate to product list and pass the uploaded image + color palette
    console.log("Proceeding with Image and Palette:", this.uploadedImage, this.colorPalette);
    this.$router.push({
      name: 'ExploreProducts',
      query: { 
        uploadedImage: this.uploadedImage,
        colorPalette: JSON.stringify(this.colorPalette) // Pass the color palette
      }
    });
  },}
};
</script>

<style scoped>

.explore-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0;
  padding-bottom: 60px; /* For bottom nav */
  background-color: #e8ded1; /* Beige background for the page */
}

.hero-banner {
  top:0;
  height: auto;
}

.image-placeholder {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f3f3;
  margin-top: 0;
}

.uploaded-image {
  max-width: 100%;
  max-height: 100%;
  width: 100vw;
  height: 50vh;
  object-fit: contain;
}

.placeholder-text {
  font-size: 18px;
  color: #5D4576;
  text-align: center;
  padding: 20px;
}

.placeholder-img {
  width: 100vw;
  height: 50vh;
}

.proceed-button {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background-color: #5D4576;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 50px;
  font-size: 16px;
  height: auto;
  cursor: pointer;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #5D4576;
}

.nav-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  height: auto;
  cursor: pointer;
}
</style>
